import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const invocation = () => (
  <Layout>
    <SEO title="Bananes Citronnées - Invocation" />
    <h3 className='underline-title'>Invocation</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Poésie<br />
        Sois pour moi la nuit Senghorienne<br />
        Nuit qui délivre<br />
        <span className="par-starting"></span>des raisons<br />
        <span className="par-starting"></span>des salons<br />
        <span className="par-starting"></span>des sophismes<br />
        <span className="par-starting"></span>des dogmatismes<br />
        <span className="par-starting"></span>des haines calculées<br />
        <span className="par-starting"></span>des carnages humanisés<br />
      </p>
      <p>
        Sois pour moi encore<br />
        <span className="par-starting"></span>le lieu de repos raisons<br />
        <span className="par-starting"></span>le havre de paix salons<br />
        <span className="par-starting"></span>le déversoir de mon trop-plein<br />
      </p>
    </div>
    <hr/>
    <p className="date-published">Bukavu, le 20 juin 1975</p>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default invocation
